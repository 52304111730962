import { HashLink as Link } from 'react-router-hash-link';
import LangAdvance from '../LangAdvance';

//ver 240121
//otão >botão 377

export default function HowTo_pt_BR(){
    document.title = "Power Mouse X - Advanced";
    return (
        <>
        <LangAdvance/>
        <div id="h-t-quick-nav">
        <b>
                    <ul style={{listStyleType:'none', margin: '0 0 0 0', padding: '0 0 0 0'}}>
                        <li><a href="#h-t-quick-search">Barra de pesquisa rápida</a></li>
                    </ul>
                </b>
            </div>
            <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Aprenda a usar o Power Mouse X</p></div>
        
                <div id="h-t-quick-search" className="main-title">
                    <p>Barra de pesquisa rápida.</p>
                </div>
                <div className="article">
                    A barra de pesquisa rápida permite acesso fácil a diversos recursos, como um site, uma pesquisa na web ou até mesmo uma pesquisa pelas suas abas.
                    Para usar a Barra de Pesquisa Rápida, basta segurar o botão direito do mouse e começar a digitar.
                    <div className="sub-title">
                        <p>Acessar outro site ou pesquisar na internet</p>
                    </div>
                    <p>
                        É possível acessar um site rapidamente ao usar a barra de pesquisa rápida.
                    </p>        
                    Para começar, digite o nome do site (mantenha o botão do mouse pressionado). A BPR tentará identificar o site desejado e irá exibi-lo. Basta digitar poucos caracteres e a barra já identifica corretamente o site desejado, e depois é só liberar o botão do mouse e você será direcionado automaticamente ao site.
                    <p>
                    <img class="h-t-img" src="../assets/quick-search-bar.gif" alt="search bar" />
                        <p>
                        Você pode uisar a Barra de pesquisa rápida para fazer buscas na internet.
                        Ao invés de digitar o endereço de um site, digite o que deseja procurar. Se a barra não identificar automaticamente o site, ela ativará a opção de pesquisa na internet, exibindo o seguinte ícone de pesquisa <img class="h-t-img" src="../assets/search.svg" alt="search icon" className="icon" /> à direita. Ao soltar o botão do mouse, a página de resultados de um mecanismo de busca será exibida.
                    Assinantes têm a opção de selecionar o serviço de busca que será utilizado.
                        </p>
                    </p>
                    <div className="sub-title">
                        <p>
                            Pesquisar em abas
                        </p>
                    </div>
                    <div>
                        Você pode usar a barra de pesquisa rápida para identificar uma aba em meio a diversas abas abertas.
                        Pressione Shift + Tab. O seguinte ícone <img class="h-t-img" src="../assets/leftRightArrow.svg" alt="search tab icon" className="icon" />  que indica "modo de busca de aba" aparecerá na barra. Para cancelar, pressione Shift + Tab novamente.
                        Você pode abrir a BPR com essa configuração ativada ao pressionar Shift + Tab ou "/" enquanto segura o botão direito do mouse. 
                        Para canhotos, a tecla "/" pode ser mais cômoda, mas seu uso não altera a condição de "modo de busca de aba".
                    </div>
                    <div className="sub-title">
                        <p>
                            Digitação com ambas as mãos
                        </p>
                    </div>
                    Digitar com uma mão pode ser desconfortável, especialmente se você precisa escrever uma frase muito longa.
                    Para digitar com as duas mãos, abra a barra de pesquisa rápida ao segurar o botão direito do mouse e pressionando a barra de espaço. Isso permite que você solte o mouse e utilize ambas as mãos para digitar, que é indicado pelo ícone de cadeado <img class="h-t-img" src="../assets/lock.svg" alt="lock icon" className="icon" /> na barra. Pressione a tecla Enter quando terminar de digitar.
                    <div className="sub-title">
                        <p>
                            Abrir um site em uma nova aba
                        </p>
                    </div>
                    <p>
                        Para navegar até um site ou realizar uma busca na internet em uma aba separada, utilize a tecla Tab.
                        Isso ativará o ícone de adição, <img class="h-t-img" src="../assets/newTab.svg" alt="plus icon" className="icon" /> indicando que o "modo de nova aba" está ativado, e os resultados serão abertos em uma nova aba. Para desativar, basta pressionar Tab novamente.
                    </p>
                    <p>
                        A barra de pesquisa rápida pode ser acionada neste modo ao usar a tecla Tab ou "+", e manter pressionado o botão direito do mouse.Para canhotos, a tecla "+" é uma alternativa conveniente, mas seu uso não altera a condição de "Modo de nova aba".
                    </p>
                    <div className="sub-title">
                        <p>
                            Suporte a diversos idiomas
                        </p>
                    </div>
                    <p>
                        Se você trabalha com diferentes configurações de teclado, é comum ocorrer erros de digitação devido ao uso do layout incorreto. Seria ótimo resolver isso com um botão, não é? Acesse a página de configurações e defina seus layouts de teclado. Se digitar por engano na barra de pesquisa rápida usando seu segundo layout, pressione F12 e o texto será corrigido para o texto correto num passe de mágica, como se estivesse digitando no layout correto o tempo inteiro. 
                        Digite F12 novamente para transformar o texto para o segundo layout de teclado.
                    </p>
                    <p>
                    Você pode usar até quatro layouts. Ao pressionar F12, a extensão tentará converter entre o layout 1 e os outros layouts, na seguinte ordem: 2&#8594;1, 3&#8594;1, 4&#8594;1, 1&#8594;2, 1&#8594;3, 1&#8594;4.
                    </p>
                    <div className="sub-title">
                        <p>
                            Mudando de ideia
                        </p>
                    </div>
                    Se desejar desativar a barra de pesquisa rápida, basta pressionar a tecla Escape.
                    </div>
                </div>
            </>
        )
}