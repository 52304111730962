import { HashLink as Link } from 'react-router-hash-link';
import Lang from '../Lang';
import QuickNav from '../QuickNav';

export default function HowTo_es(){
    document.title = "Power Mouse X - Advanced";
    return (
        <>
        <Lang/>
        <QuickNav chapters={["Después de la instalación","Gestos","Combos de ratón","Nueva pestaña personalizada","Opciones de arrastre","Predicciones","Configurar botones laterales","Icono de extensión de fijado","Desactivar extensión"]}/>
            <div className="main-container">
                <div id="h-t-languages"></div>
                <div className="page-header"><p>Aprenda a utilizar Power Mouse X</p></div>
        
                <div id="h-t-after-installation" className="main-title"><p>Después de la instalación</p></div>
                <div className="article">
                    Si acaba de instalar la extensión, funcionará en las nuevas pestañas, pero puede que no funcione en las pestañas que ya estaban abiertas. <mark>Asegúrese de recargar las pestañas abiertas anteriormente.
                    Tenga en cuenta también que (todas) las extensiones <b>no funcionan</b> en <b>plataformas de descarga de complementos</b> como la tienda web de Chrome ni <b>en sitios sin URL</b> como la <b>primera página</b> al abrir una nueva pestaña.</mark> Véase Sección de <a href="#h-t-custom-new-tab">Nueva pestaña personalizada</a> para algunas soluciones.
        
                    <p>
                        No se sienta abrumado por el tamaño de esta guía, la mayor parte es bastante obvia. Si utiliza un trackpad, es posible que desee comprobar las <a href="#h-t-drag-options">Opciones de arrastre</a> en su sección primero.
                    </p>
                </div>
        
                <div id="h-t-gestures" className="main-title">
                    <p>Gestos</p>
                </div>
                <div className="article">
                    <p>
                        Mantenga pulsado el botón derecho del ratón y arrástrelo hacia un lado para hacer un "gesto".
                        Aparecerá un anillo alrededor del cursor del ratón con ocho iconos.
                        Sitúe el puntero del ratón sobre el icono que representa la acción que desea realizar y, a continuación, suelte el botón del ratón.
                    </p>
                    <p>
                        Los gestos por defecto son:
                    </p><img class="h-t-img" src="../assets/ring.jpg" id="h-t-ring-img" alt="ring" width="225px" height="225px" />
                    <div style={{float:"left", marginRight:"32px"}}>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-w.svg" alt="west arrow"/> ir a la página anterior.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-n.svg" alt="north arrow"/> ir a la página principal.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-e.svg" alt="east arrow"/> ir a la página siguiente.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-s.svg" alt="south arrow"/> Actualizar página.<br />
                    </div>
                    <div>
                        <img class="h-t-img arrow-icon" src="../assets/arrow-nw.svg" alt="north west arrow"/> ir al sitio: www.Google.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-ne.svg" alt="north east arrow"/> ir al sitio: www.Youtube.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-se.svg" alt="south east arrow"/> ir al sitio: www.Facebook.com.<br />
                        <img class="h-t-img arrow-icon" src="../assets/arrow-sw.svg" alt="south west arrow"/> ir al sitio: Tutorial de ampliación.<br />
                    </div>
                    <p>
                        El anillo es personalizable, en el menú de ajustes, puede cambiar \ añadir \ eliminar acciones, así como, cambiar sus ubicaciones. 
                        El tamaño del anillo también es personalizable, le animamos a que lo haga tan pequeño como le resulte cómodo.
                    </p>
                    <p>
                    Puede activar el anillo y elegir un gesto sin mover el ratón, lo que resulta especialmente útil para las personas que utilizan un touchpad. 
                    Para ello, mantenga pulsado el botón derecho del ratón y, a continuación, pulse y mantenga pulsadas las teclas de flecha del teclado que correspondan a la posición del gesto en el anillo (puede pulsar dos flechas para gestos situados en diagonal). 
                    Suelte el botón derecho mientras mantiene pulsadas las teclas de flecha.
                    </p>
                    <p>
                    Si los gestos del ratón interfieren con algún otro uso del botón derecho, puede omitir el mecanismo de gestos manteniendo pulsado el botón de control.
                    Para más información y soluciones permanentes, consulte la <a href="#h-t-disable-extension">Desactivar extensión</a> en su sección
                    </p>
                    <p>
                        A continuación se muestran las acciones soportadas y sus iconos, tenga en cuenta que algunas de las acciones también están disponibles como combos de ratón y que las acciones adicionales están disponibles mediante <a href="#h-t-drag-options">Opciones de arrastre</a>.
                    </p>
                    <p>
                        Si no encuentra aquí la acción que desea, puede <Link to="/en/contact">solicitarla y</Link>  estudiaremos la posibilidad de incorporarla en futuras versiones.
                    </p>
                    <table>
                        <tr>
                            <td style={{borderWidth: '0'}}>
                                <table style={{verticalAlign:"bottom"}}>
                                    <tr><td>Ir a la página de predefinición</td><td><img src="../assets/go2Site.svg" alt="go to predefine page icon" className="icon" /></td></tr>
                                    <tr><td>Ir a la página principal</td><td><img src="../assets/go2Main.svg" alt="go to the main page icon" className="icon" /></td></tr>
                                    <tr><td>Ir a la página siguiente</td><td><img src="../assets/goForward.svg" alt="go to the next page icon" className="icon" /></td></tr>
                                    <tr><td>Ir a la página anterior</td><td><img src="../assets/goBack.svg" alt="go to the previous page icon" className="icon" /></td></tr>
                                    <tr><td>Ir 7 páginas atrás (Super back)</td><td><img src="../assets/goSuperBack.svg" alt="Go 7 pages back, Super back, icon" className="icon" /></td></tr>
                                    <tr><td>Ir a la parte superior de la página</td><td><img src="../assets/goUp.svg" alt="go to the top of the page icon" className="icon" /></td></tr>
                                    <tr><td>Ir al final de la página</td><td><img src="../assets/goDown.svg" alt="go to the bottom of the page icon" className="icon" /></td></tr>
                                    <tr><td>Ir a la pestaña vista anteriormente</td><td><img src="../assets/previousViewedTab.svg" alt="go to the previous viewed tab icon" className="icon" /></td></tr>
                                    <tr><td>Cambiar a la primera pestaña</td><td><img src="../assets/go2FirstTab.svg" alt="Switch to the first tab icon" className="icon" /></td></tr>
                                    <tr><td>Cambiar a la última pestaña</td><td><img src="../assets/go2LastTab.svg" alt="Switch to the last tab icon" className="icon" /></td></tr>
                                    <tr><td>Cambiar pestaña anterior</td><td><img src="../assets/go2PreviousTab.svg" alt="Switch to the previous tab icon" className="icon" /></td></tr>
                                    <tr><td>Cambiar a la pestaña siguiente</td><td><img src="../assets/go2NextTab.svg" alt="Switch to the next tab icon" className="icon" /></td></tr>
                                    <tr><td>Abrir nueva pestaña</td><td><img src="../assets/newTab.svg" alt="open new tab icon" className="icon" /></td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>Abrir nueva ventana</td><td><img src="../assets/newWindow.svg" alt="open new window icon" className="icon" /></td></tr>
                                    <tr><td>Abrir nueva ventana privada</td><td><img src="../assets/newPrivateWindow.svg" alt="open new private window icon" className="icon" /></td></tr>
                                    <tr><td>Cerrar pestaña</td><td><img src="../assets/closeTab.svg" alt="close tab icon" className="icon" /></td></tr>
                                    <tr><td>Cerrar pestañas a la derecha</td><td><img src="../assets/closeRightTabs.svg" alt="Close tabs to the right icon" className="icon" /></td></tr>
                                    <tr><td>Cerrar pestañas a la izquierda</td><td><img src="../assets/closeLeftTabs.svg" alt="Close tabs to the left" className="icon" /></td></tr>
                                    <tr><td>Cerrar todas las pestañas de este sitio</td><td><img src="../assets/closeSiteTabs.svg" alt="Close all tabs of this site icon" className="icon" /></td></tr>
                                    <tr><td>Cierre todas las demás pestañas</td><td><img src="../assets/closeOtherTabs.svg" alt="Close all other tabs icon" className="icon" /></td></tr>
                                    <tr><td>Restaurar recientemente cerrado</td><td><img src="../assets/restore.svg" alt="restore recently closed icon" className="icon" /></td></tr>
                                    <tr><td>Silenciar \ Desilenciar pestaña</td><td><img src="../assets/mute.svg" alt="mute icon" className="icon" />&nbsp;<img src="../assets/unmute.svg" alt="unmute icon" className="icon" /></td></tr>
                                    <tr><td>Duplicar pestaña</td><td><img src="../assets/duplicateTab.svg" alt="duplicate tab icon" className="icon" /></td></tr>
                                    <tr><td>Fijar \ Desfijar pestaña</td><td><img src="../assets/pinTab.svg" alt="pin icon" className="icon" />&nbsp;<img src="../assets/unpinTab.svg" alt="unpin icon" className="icon" /></td></tr>
                                    <tr><td>Hacer pestaña pública \ privada</td><td><img src="../assets/public.svg" alt="make tab public icon" className="icon" />&nbsp;<img src="../assets/private.svg" alt="make tab private icon" className="icon" /></td></tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                </table>
                            </td>
                            <td style={{borderWidth: '0'}}>
                                <table>
                                    <tr><td>Mover la pestaña a la primera posición</td><td><img src="../assets/moveTabToStart.svg" alt="Move tab to the first position icon" className="icon" /></td></tr>
                                    <tr><td>Mover la pestaña a la última posición</td><td><img src="../assets/moveTabToEnd.svg" alt="Move tab to the last position icon" className="icon" /></td></tr>
                                    <tr><td>Minimizar ventana</td><td><img src="../assets/minimize.svg" alt="Minimize window icon" className="icon" /></td></tr>
                                    <tr><td>Maximizar ventana</td><td><img src="../assets/maximize.svg" alt="Maximize window icon" className="icon" />&nbsp;<img src="../assets/unmaximize.svg" alt="unmaximize window icon" className="icon" /></td></tr>
                                    <tr><td>Pantalla completa</td><td><img src="../assets/fullScreen.svg" alt="Full screen icon" className="icon" />&nbsp;<img src="../assets/unfullScreen.svg" alt="unfull screen icon" className="icon" /></td></tr>
                                    <tr><td>Establecer el tamaño y la ubicación de la ventana</td><td><img src="../assets/setWindowParameters.svg" alt="change window size and location icon" className="icon" /></td></tr>
                                    <tr><td>Actualizar página</td><td><img src="../assets/refresh.svg" alt="refresh icon" className="icon" /></td></tr>
                                    <tr><td>Actualizar página sin caché</td><td><img src="../assets/refreshWithoutCache.svg" alt="Refresh without cache icon" className="icon" /></td></tr>
                                    <tr><td>Imprimir página</td><td><img src="../assets/printPage.svg" alt="print page icon" className="icon" /></td></tr>
                                    <tr><td>Pegar un texto</td><td><img src="../assets/pasteText.svg" alt="paste a text icon" className="icon" /></td></tr>
                                    <tr><td>Copiar URL de pestaña</td><td><img src="../assets/copyURL.svg" alt="copy tab url icon" className="icon" /></td></tr>
                                    <tr><td>Aumentar \ alejar el zoom</td><td><img src="../assets/zoomIn.svg" alt="zoom in icon" className="icon" />&nbsp;<img src="../assets/zoomOut.svg" alt="zoom out icon" className="icon" /></td></tr>
                                    <tr><td>&nbsp;</td><td>&nbsp;</td></tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    <p>
                        La mayoría de las opciones de acción se explican por sí mismas, unas pocas requieren más aclaraciones:
                        <ul>
                            <li>Algunas de las acciones requerirán proporcionar información adicional, por ejemplo la acción "Pegar un texto" requiere el texto a pegar y el nombre que se mostrará al pasar el ratón sobre la acción en el anillo de opciones.</li>
                            <li>La acción "Ir a la página principal" le lleva a la página principal del dominio.</li>
                            <li>La acción "Actualizar página" es lo mismo que pulsar el botón Recargar del navegador en modo no privado.</li>
                            <li>La acción "Actualizar página sin caché" descarga de nuevo todos los datos de la página, es lo mismo que pulsar el botón Recargar del navegador en modo privado. </li>
                            <li>Algunas de las acciones dependen del estado, por ejemplo, si utiliza la acción Pantalla completa en el modo de pantalla completa, saldrá del modo de pantalla completa.</li>
                        </ul>
                    </p>    
                </div>

                {/*<div id="h-t-quick-search" className="main-title">
                    <p>Barra de búsqueda rápida</p>
                </div>
                <div className="article">
                    La barra de búsqueda rápida le permite ir instantáneamente a un sitio, buscar en la web o buscar una ficha.
                    Para utilizar el QSB sólo tiene que mantener pulsado el botón derecho del ratón y empezar a escribir.
                    <div className="sub-title">
                        <p>Ir a otro sitio y buscar en la web</p>
                    </div>
                    <p>
                        Puede navegar a un sitio casi instantáneamente utilizando la barra de búsqueda rápida.
                    </p>        
                    Comience a escribir el nombre del sitio (no suelte aún el botón del ratón). La barra de búsqueda rápida (QSB) intentará predecir el sitio que está buscando y lo mostrará. Una vez que la barra predice el sitio correctamente (normalmente después de teclear sólo unos pocos caracteres) puede soltar el botón del ratón y será enviado al sitio.
                    <p>
                    <img class="h-t-img" src="../assets/quick-search-bar.gif" alt="search bar" />
                        <p>
                        Puede utilizar la barra de búsqueda rápida para buscar en la web.
                    Escriba una consulta de búsqueda en lugar del nombre de un sitio, cada vez que la barra de búsqueda no pueda predecir el sitio asumirá que usted quiere buscar en la web y mostrará un símbolo de búsqueda <img class="h-t-img" src="../assets/search.svg" alt="search icon" className="icon" /> a su derecha. 
                    Una vez que suelte el botón del ratón, irá a la página de resultados de búsqueda de un motor de búsqueda.
                    Las personas con suscripción de pago pueden elegir el motor de búsqueda.
                        </p>
                    </p>
                    <div className="sub-title">
                        <p>
                            Buscar una ficha
                        </p>
                    </div>
                    <div>
                        Si tiene muchas pestañas abiertas, la forma más fácil de encontrar y cambiar a otra pestaña puede ser utilizando la barra de búsqueda rápida.
                        Pulse Mayúsculas + Tabulador, Verá un <img class="h-t-img" src="../assets/leftRightArrow.svg" alt="search tab icon" className="icon" />  icono que aparece en la barra indicando "modo pestaña de búsqueda". Si desea cancelarlo, pulse de nuevo Mayúsculas + Tabulador.
                        Puede abrir la barra de búsqueda rápida (QSB) con esta opción activada pulsando Mayús + Tabulador o "/" mientras mantiene pulsado el botón derecho del ratón. Utilizar el botón "/" puede ser más fácil para los zurdos, pero a diferencia de pulsar Mayúsculas + Tabulador, pulsar "/" después de que se haya abierto el QSB no cambiará el estado del "modo de búsqueda de pestañas".
                    </div>
                    <div className="sub-title">
                        <p>
                            Mecanografía con ambas manos
                        </p>
                    </div>
                    Teclear con una sola mano puede resultar incómodo, sobre todo cuando está escribiendo una consulta de búsqueda larga.
                    Para poder escribir con ambas manos, abra la barra de búsqueda rápida manteniendo pulsado el botón derecho del ratón y pulsando la barra espaciadora. Ya puede soltar el botón del ratón y utilizar ambas manos, un símbolo de bloqueo en la barra <img class="h-t-img" src="../assets/lock.svg" alt="lock icon" className="icon" /> indica que ésta permanecerá Pulse la tecla Intro cuando termine de escribir.
                    <div className="sub-title">
                        <p>
                            Abrir un sitio en una nueva pestaña
                        </p>
                    </div>
                    <p>
                        Si desea ir a un sitio o buscar en la web en una nueva pestaña, pulse la tecla Tab.
                        Verá que aparece un signo más <img class="h-t-img" src="../assets/newTab.svg" alt="plus icon" className="icon" /> en la barra que indica "Modo nueva pestaña", lo que significa que los resultados estarán en una nueva pestaña. Si desea cancelar, pulse Tab de nuevo.
                    </p>
                    <p>
                        Puede abrir la barra de búsqueda rápida con esta opción activada pulsando Tabulador o "+" mientras mantiene pulsado el botón derecho del ratón. Utilizar el botón "+" puede resultar más fácil para los zurdos, pero, a diferencia del botón de pestaña, si lo pulsa después de que se haya abierto la barra de búsqueda rápida no cambiará el estado de "Modo de nueva pestaña".
                    </p>
                    <div className="sub-title">
                        <p>
                            Soporte multilingüe
                        </p>
                    </div>
                    <p>
                        Si tiene más de una distribución de teclado, probablemente se habrá encontrado muchas veces escribiendo con la distribución equivocada. ¿No estaría bien poder solucionarlo pulsando un botón? Vaya a la página de configuración y configure sus distribuciones de teclado, la próxima vez que escriba por error en la barra de búsqueda rápida utilizando su 2ª distribución pulse F12 y el texto se transformará mágicamente en el texto correcto como si estuviera escribiendo su 1ª distribución. Vuelva a teclear F12 para transformar el texto a la disposición del 2º teclado.
                    </p>
                    <p>
                    Puede utilizar hasta cuatro trazados, cada pulsación de F12 hará que la extensión intente convertir entre el trazado 1 y los otros trazados, en este orden: 2&#8594;1, 3&#8594;1, 4&#8594;1, 1&#8594;2, 1&#8594;3, 1&#8594;4.
                    </p>
                    <div className="sub-title">
                        <p>
                            Cambiar de opinión
                        </p>
                    </div>
                    En cualquier momento puede cancelar la barra de búsqueda rápida pulsando el botón Escape.
    </div>*/}
                    <div id="h-t-mouse-combos" className="main-title">
                        <p>
                            Combos de ratón
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                            <b>Cerrar pestañas</b> haciendo doble clic en el botón *derecho o central del ratón.
                            <br />Puede cambiar la velocidad necesaria para el doble toque en los ajustes.
                            <br /><i>*Tenga en cuenta que en Edge el combo de doble pulsación del botón derecho sólo funciona si se hace mientras se mueve el ratón, el combo de doble pulsación del botón central funciona normalmente.</i>
                        </p>
                        <p>
                            <b>Abrir una nueva pestaña</b> pulsando el botón izquierdo mientras mantiene pulsado el botón derecho.
                        </p>
                        <p>
                            <b>Cambiar pestañas</b> desplazando el botón de la rueda mientras se pulsa el botón derecho.
                            <br />Puede cambiar la dirección de cambio de pestaña en los ajustes.
                        </p>
                        <p>
                            <b>Ir a la parte superior o inferior de la página</b> desplazando el botón central hacia arriba o hacia abajo mientras se mantiene pulsado.
                        </p>
                        <p>
                            <b>Acercar o alejar el zoom</b> desplazando el botón central hacia arriba o hacia abajo mientras se mantiene pulsado.
                            <br />Esta función utiliza el mismo combo que la función "Ir a la parte superior o inferior de la página". Para utilizar esta función, tendrá que sustituir la otra función en los ajustes.
                        </p>
                        <p>
                            Todos los combos se pueden desactivar en los ajustes.
                        </p>
                    </div>
                    <div id="h-t-custom-new-tab" className="main-title">
                        <p>
                            Nueva pestaña personalizada
                        </p>
                    </div>
                    <div className="article">
                        <img class="h-t-img" src="../assets/starting%20page%20screenshot.png" id="h-t-custom-new-tab-img" alt="custom new tab" width="360px" height="225px" />
                        <p>
                            Todas las extensiones del navegador no pueden funcionar en la página de nueva pestaña predeterminada. Para evitar este problema, Power Mouse X utiliza una página de nueva pestaña personalizada.
                        </p>
                        <p>
                            Al igual que la página de nueva pestaña predeterminada, nuestra nueva pestaña personalizada muestra accesos directos a sitios. Puede desactivarlas o configurarlas en la página de configuración.
                        </p>
                        <p>
                            Puede abrir esta nueva pestaña personalizada de dos maneras:<br/>
                            1) Utilice la <a href="#h-t-mouse-combos">combinación del ratón</a> para una nueva pestaña. Debería abrir una nueva pestaña personalizada, a menos que haya cambiado la configuración para abrir la nueva pestaña predeterminada.<br/>
                            2) Si no desea utilizar el combo o está utilizando un trackpad, puede establecer la nueva pestaña personalizada de Power Mouse X como su pestaña predeterminada. Para ello debe descargar un complemento llamado "Power Mouse X Sustitución de nueva pestaña".
                            Siempre puede revertir el cambio desactivando el complemento.<br />
                            Tenga en cuenta que seguirá obteniendo la página de nueva pestaña original en el modo incógnito de Chrome y Edge.
                        </p>
                        Descargue el sustituto de la nueva pestaña Power Mouse X para:<br/>
                        <a href="https://chrome.google.com/webstore/detail/power-mouse-x-new-tab-rep/lokfekmibhakginjdmfmiafjnocinefd?hl=en-GB&authuser=0">Chrome</a>&nbsp; 
                        <a href="https://microsoftedge.microsoft.com/addons/detail/power-mouse-x-new-tab-rep/cmapphcgabfcjcnehjchcmgodpmalafg">Edge</a>&nbsp;
                        <a href="https://addons.mozilla.org/en-US/firefox/addon/powermousex-newtab-replacement/">Firefox</a>
                    </div>
                    <div id="h-t-drag-options" className="main-title">
                        <p>
                            Opciones de arrastre
                        </p>
                    </div>
                    <div className="article">        
                        <div>
                        <table id="h-t-options-table" style={{float:"right", marginLeft:"24px"}}>
                            <tr>
                                <td></td><td><b>Texto seleccionado</b></td><td><b>Vínculo</b></td><td><b>Imagen</b></td>
                            </tr>
                            <tr>
                                <td><b>Copiar<sup>(1)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Buscar en la red</b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Buscar en Wikipedia<sup>(2)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>Traducir<sup>(2)</sup></b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>Resaltar</b></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td></td><td></td>
                            </tr>
                            <tr>
                                <td><b>Abrir en una nueva pestaña</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Abrir en una nueva pestaña de fondo</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Abrir en una ventana pública / privada<sup>(3)</sup></b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                            <tr>
                                <td><b>Abrir en una nueva ventana</b></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>                            
                            <tr>
                                <td><b>Descargar</b></td><td></td><td></td><td><img class="h-t-img" src="../assets/v.svg" alt="V" className="v-icon" /></td>
                            </tr>
                        </table>
                        <p>
                                Al arrastrar los elementos de la página web se abre un anillo de opciones. Diferentes elementos hacen que aparezcan diferentes opciones.<br /> La tabla de la derecha resume las opciones disponibles.
                            </p>
                        <img class="h-t-img" src="../assets/drag%20ring.jpg" id="h-t-ring-img" alt="drag ring" width="225px" height="225px" style={{float:"none"}} />

                            <p>
                    Si el anillo interfiere con algún otro uso del arrastre de elementos, puede anularlo manteniendo pulsado el botón de control.
                    Para más información y soluciones permanentes, consulte la <a href="#h-t-disable-extension">Desactivar extensión</a> en su sección
                    </p>
                        </div>
                        <div>
                        <p>
                            <i>
                                (1) Sólo se pueden copiar imágenes png.
                            <br />(2) Puede establecer el idioma de destino en los ajustes.
                            <br />(3) Dependiendo de su modo de ventana actual.
                            </i>
                        </p>
                        <p>
                            Las opciones de arrastre pueden desactivarse en la página de configuración.
                        </p>
                        </div>
                    </div>
                    <div id="h-t-predictions" className="main-title"><p>Predicciones (no disponible en Firefox)</p></div>
                    <div className="article">        
                        <p>Power mouse X intenta predecir su próxima página. 
                        Mientras navega por un sitio, como un sitio de compras, un motor de búsqueda, un foro, etc., puede utilizar estos métodos para ir a la página siguiente: mediante el gesto \"Avanzar\", pulsando Alt + &#8594; o utilizando el botón de avance del ratón (disponible en algunos ratones). Utilice esta función en Youtube para ver el siguiente vídeo de la lista de reproducción.
                        </p>
                        <p>Las predicciones funcionan mejor en unos sitios más que en otros, a veces funciona de maravilla, a veces no funciona y a menudo sólo funciona a partir de la segunda página de la lista de resultados. Experimente con él en sus sitios favoritos. </p>
                    </div>
                    <div id="h-t-side-buttons" className="main-title"><p>Configurar botones laterales (no disponible en Firefox)</p></div>
                    <div className="article">        
                        <p>
                            Algunos ratones de computadora tienen botones laterales adicionales que suelen utilizarse para navegar rápidamente hacia atrás y hacia delante.
                            PowerMouseX le permite añadir funcionalidad a estos botones.
                            </p>
                            <p> 
                            En la página de configuración, elija en la barra de desplazamiento una de las decenas de acciones que desea activar al pulsar un botón. 
                            También tendrá que elegir el botón de avance (botón nº 5) objetivo principal:<br/>
                            Si lo ajusta en <b>"adelante"</b> el navegador intentará primero navegar hacia delante y si dicha opción no existe realizará la acción. <br/>
                            Si lo ajusta en <b>"adelante y predicción"</b> el navegador intentará primero navegar hacia adelante y si tal opción no existe intentará <a href="#h-t-predictions">predecir</a> la página siguiente, si no puede realizará la acción.<br/>
                            Si lo ajusta en <b>"acción"</b>, al pulsar el botón de avance sólo se realizará la acción y nunca se utilizará para navegar hacia delante.
                            </p>
                            <p>
                            Si dispone de una suscripción de pago, también puede añadir funcionalidad a una combinación realizada pulsando los botones laterales mientras mantiene pulsado uno de los botones primarios del ratón.
                        </p>
                    </div>
                    <div id="h-t-pin-extension-icon" className="main-title">
                        <p>
                            Icono de extensión de fijado
                        </p>
                    </div>
                    <div className="article">  
                        <img class="h-t-img" src="../assets/popup%20menu%20screenshot.png" id="h-t-pop-up-menu-img" alt="pop-up menu" width="200px" height="223px" />
                        <p>
                            La forma más sencilla de acceder a la configuración de la extensión o de ponerla en pausa en un sitio es a través del menú emergente que aparece al pulsar el icono de la extensión. Si el icono X de Power Mouse no aparece en la barra de herramientas, puede solucionarlo de esta forma:
                        </p>
                        <p>
                            <b>En Chrome</b> Pulse el icono del rompecabezas situado junto a la barra de direcciones. A continuación, pulse el icono del alfiler situado junto al nombre de la extensión.
                            <br /><b>En Edge</b> Pulse el icono del rompecabezas situado junto a la barra de direcciones. A continuación, pulse el icono del ojo situado junto al nombre de la extensión.
                            <br /><b>En Firefox</b> Haga clic con el botón derecho del ratón junto a la barra de direcciones y seleccione "Personalizar barra de herramientas". A continuación, arrastre el icono de la extensión a la barra de herramientas.
                        </p>
                    </div>
                    <div id="h-t-disable-extension" className="main-title">
                        <p>
                            Desactivar extensión
                        </p>
                    </div>
                    <div className="article">        
                        <p>
                            Si los gestos del ratón o las opciones de arrastre interfieren temporalmente, por ejemplo impidiendo que aparezca un menú emergente, puede evitar el problema manteniendo pulsado el botón de control antes de utilizar el ratón.
                        </p>
                        <p>
                            Otra opción es desactivar la extensión para su sitio actual o para todos los sitios pulsando <img class="h-t-img" src="../assets/icon128.png" alt="Power Mouse X icon" className="icon" /> en la barra de herramientas (junto a la barra de direcciones) y alternando los interruptores.                             Si falta el icono, lea la sección anterior.
                        </p>
                    </div>
                </div>                           
            </>
        )
}